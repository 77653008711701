import React from 'react';
import { ThemeProvider, Styled } from 'theme-ui';
import Helmet from 'react-helmet';
import { Flex, Box } from 'rebass';

import { MenuContextProvider } from './Menu/context';

import { SearchContextProvider } from './Search/context';
import Navbar from './Navbar';
import Footer from './Footer';
import theme from '../gatsby-plugin-theme-ui/index';

import './reset.css';

import CookieConsent from 'react-cookie-consent';
import { useLocation } from "@reach/router" // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'

const Layout = ({ children }) => {

  return (
    <ThemeProvider theme={theme}>
      <Styled.root>
        <LayoutComponents children={children} />
      </Styled.root>
    </ThemeProvider>
  );
};

const LayoutComponents = ({ children }) => {
  return (
    <Box
      bg="background"
      sx={{
        width: '100%',
      }}
    >
      <Helmet>
        <html lang="en" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://www.google-analytics.com" />
        <meta name="google-site-verification" content="h1BCnJXgAHVKmMIKi8AzTatMv0sC88rCtMZvHKYhnxs" />
      </Helmet>

      <Flex flexDirection="column" style={{ minHeight: '100vh' }}>
        <MenuContextProvider>
          <SearchContextProvider>
            <Navbar />{' '}
          </SearchContextProvider>
        </MenuContextProvider>

        <Box
          as="main"
          flex="1"
          width={1}
          style={{ maxWidth: 1300, height: '100%' }}
          mx="auto"
          mt={'106px'}
        >
          {children}
        </Box>

        <Footer />
        <CookieConsent
          location="bottom"
          enableDeclineButton="true"
          cookieName="gatsby-gdpr-google-analytics"
          onAccept = {() => initializeAndTrack(useLocation)}
          >
        This website uses cookies to enhance the user experience.
        </CookieConsent>
      </Flex>
    </Box>
  );
};

export default Layout;
